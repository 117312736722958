



import { Vue, Component, Prop } from 'vue-property-decorator'
import { Chart } from '@/models'

@Component({})
export default class MixedChart extends Vue {
  @Prop({ type: Object, required: true }) chart!: Chart
  @Prop({ type: Object, required: true }) result!: any
  @Prop({ type: Object, default: () => ({}) }) queryOptions!: Record<
    string,
    any
  >
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Boolean, default: false }) loading!: boolean

  get events() {
    return (this.result.events || []).map((event: any) => {
      return {
        ...event,
        start: new Date(event.start),
        end: new Date(event.end)
      }
    })
  }
}
